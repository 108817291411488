<template>
    <!-- 导航 -->
    <page-head title="智能比赛报告">
        <template #right>
            <filter-date :date="date" @change="handleChangeDate"></filter-date>
        </template>
    </page-head>
    <div class="matchList">
        <div class="matchItem" v-for="item in showData.showList" :key="item.id" @click="menuClick(item)">
            <p class="title">{{ item.title }}</p>
            <div class="itemContent">
                <div class="left">
                    <img :src="item.report.ownSideTeam.logo" alt="">
                    <span>{{ item.report.ownSideTeam.name }}</span>
                </div>
                <div class="center">
<!--                    <span class="score">{{ item.score ? item.score.replace(':', '-') : '0 - 0' }}</span>-->
                    <span class="date">{{ item.report.date }}</span>
                    <span class="pitch">{{ item.report.court }}</span>
                </div>
                <div class="right">
                    <img :src="item.report.againstTeam.logo" alt="">
                    <span>{{ item.report.againstTeam.name }}</span>
                </div>
            </div>
        </div>
        <no-data v-if="!matchList.length"></no-data>
    </div>
    <pagination-com v-if="matchList.length" :current-page="showData.page" :total-page="showData.totalPage" @changePage="changePage"></pagination-com>

</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "index",
    setup() {

        const router = useRouter()
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            matchList: [],
            showData: {},
            // 显示的菜单，分页
            query: {
                pageNum: 1,
                pageSize: 4,
                publish: true,
                type: 0,
                status: 3,
                sportType: 'soccer'
            },
            date: [
                proxy.$moment().subtract(6, 'months').format('YYYY/MM/DD'),
                proxy.$moment().format('YYYY/MM/DD')
            ]
        })

        const getMatch = async () => {
            let list = [];
            state.query.start = state.date[0];
            state.query.end = state.date[1];
            await proxy.$server.getMatchReportSchedule(state.query, {'x-tenant-id': proxy.$Config.PARENT_ID})
                .then(res => {
                    list = res.data;
                })
            await proxy.$server.getMatchReportSchedule(state.query)
                .then(res => {
                    list = list.concat(res.data);
                })
            
            state.matchList = list;
            const {pageNum, pageSize} = state.query;
            state.showData = proxy.$utils.paginationFn(state.matchList, pageNum, pageSize);
        }

        onMounted(() => {
            getMatch()
        })

        const itemBg = (value) => {
            return value.length > 1
                ? `linear-gradient(110.54deg, ${value[0]} 13.63%, ${value[1]} 88.76%)`
                : value[0];
        }


        const changePage = (page) => {
            const {pageSize} = state.query;
            state.showData = proxy.$utils.paginationFn(state.matchList, page, pageSize);
        }

        // 跳转详情页
        const menuClick = (item) => {
            let id = item.id; // v2、v1用报告id、v3用工单id
            let version = 'v3';
            if (item.history) {
                id = item?.report.id;
                version = item?.report?.version || 'v1';
            }
            router.push({
                path: '/matchReport/detail',
                query: {
                    url: `${proxy.$Config.reportURL}/match-report-screen/index?id=${id}&version=${version}&nosign=true`
                }
            })
        }

        // 时间筛选时
        const handleChangeDate = (date) => {
            state.date = date;
            getMatch();
        }

        return {
            ...toRefs(state),
            store,

            itemBg,
            changePage,
            menuClick,
            handleChangeDate
        }
    }
}
</script>

<style scoped lang="scss">
.navigation-mian {
    width: 1365px;
    .filter-date {
        margin-left: auto;
        margin-right: 0;
    }
}
.no-data {
    width: 1365px;
    height: 500px;
}
.matchList {
    display: flex;
    flex-flow: wrap;
    margin-top: 80px;
    margin-left: 60px;

    .matchItem {
        display: flex;
        flex-direction: column;
        width: 656px;
        height: 316px;
        background: linear-gradient(180deg, #12122E 0%, #151443 100%);
        box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(40px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 8px;
        color: #fff;
        margin-bottom: 32px;
        margin-right: 32px;
        overflow: hidden;

        .title {
            height: 62px;
            line-height: 62px;
            background: linear-gradient(90deg, #162147 0%, #191C3A 100%);
            font-size: 26px;
            color: rgba(255, 255, 255, .8);
            border-bottom: 1px solid #4E5C8D;
            padding: 0 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            width: 88.6px;
            height: auto;
            margin-bottom: 10px;
        }

        .itemContent {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            .left, .right {
                flex: 1;
                padding: 0 20px;
                font-size: 25.2561px;

                span {
                    height: 70px;
                    line-height: 35px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2; 
                }
            }

            .center {
                width: 30%;

                span {
                    display: block;
                }

                .score {
                    font-size: 72px;
                    line-height: 90px;
                    font-family: akrobatBold;
                    margin-bottom: 10px;
                }

                .date, .pitch {
                    color: rgba(255, 255, 255, .8);
                }

                .date {
                    font-size: 28px;
                    font-family: akrobatRegular;
                    line-height: 35px;
                    margin-bottom: 2px;
                }

                .pitch {
                    line-height: 35px;
                    font-size: 24px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2; 
                }
            }
        }
    }

}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 950px;
}
</style>